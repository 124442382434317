<template>
  <CSidebar class="bg-clr" fixed :minimize="minimize" :show.sync="show">
    <CSidebarHeader class="bg-clr" :withSubheader="subheader">
      <div class="col-12 logo">
        <div>
          <a
            :href="getOrgWebsite || 'javascript:void(0)'"
            :target="getOrgWebsite ? '_blank' : '_self'"
          >
            <img
              class="mw-100 h-auto"
              :src="getHeaderLogo"
              @error="$event.target.src = '/img/new_tf_logo.svg'"
            />
          </a>
        </div>
      </div>
    </CSidebarHeader>
    <CRenderFunction flat :content-to-render="sideBarNav" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="minimize = !minimize"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";
import { mapGetters } from "vuex";
import { Role } from "@/helpers/helper";
export default {
  name: "TheSidebar",
  data() {
    return {
      minimize: false,
      subheader: true,
      nav,
      show: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters([
      "getRoleScopes",
      "getCandidateListUrlBasedOnRole",
      "getIsParentUser",
      "getImages",
      "getOrgDetail",
      "getUserAcl",
    ]),
    getHeaderLogo() {
      return this.getImages?.header_logo;
    },
    getOrgWebsite() {
      return this.getOrgDetail?.website || "";
    },
    dashboardNav() {
      let role = this.getRoleScopes[0];

      if (Role.systemAdmin === role) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Dashboard"],
          },
          {
            _name: "CSidebarNavItem",
            name: "System Dashboard",
            to: "/dashboard",
            icon: "cil-library",
          },
        ];
      } else if ([Role.customerAdmin, Role.customerRecruiter].includes(role)) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Dashboard"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Recruiter Dashboard",
            to: "/recruiter-dashboard",
            icon: "cil-library",
          },
        ];
      }

      return [];
    },
    candidateNav() {
      let role = this.getRoleScopes[0];
      if (role === Role.customerAdmin || role === Role.customerRecruiter) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Candidates"],
          },
          {
            _name: "CSidebarNavItem",
            name: "List Candidates",
            to: this.getCandidateListUrlBasedOnRole,
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Job Candidates Status",
            to: "/job-candidates-status?tab=allTab",
            icon: "cil-library",
          },
        ];
      } else if (role === Role.systemAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Candidates"],
          },
          {
            _name: "CSidebarNavItem",
            name: "List Candidates",
            to: this.getCandidateListUrlBasedOnRole,
            icon: "cil-library",
          },
        ];
      }
      return [];
    },
    hospitalNav() {
      let role = this.getRoleScopes[0];
      // hot fix in production
      // if customerAdmin or customerRecruiter for now we're removing hospital menu
      if (role === Role.customerAdmin || role === Role.customerRecruiter) {
        return [];
      }
      if (role === Role.customerAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Hospitals"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Hospital Recruiters",
            to: "/add-hospital-recruiters",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Registered Hospital Recruiters",
            to: "/hospital-recruiters-list",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Registered Hospital Recruiters",
            to: "/hospital-recruiters-list",
            icon: "cil-library",
          },
        ];
      } else if (role === Role.systemAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Hospitals"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Hospital",
            to: "/add-hospital",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Hospital Administrator",
            to: "/add-hospital-admin",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Registered Hospitals",
            to: "/hospital-list",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Registered Hospital Administrators",
            to: "/hospital-admin-list",
            icon: "cil-library",
          },
        ];
      }

      return [];
    },
    jobsNav() {
      let role = this.getRoleScopes[0];

      if (role === Role.systemAdmin || role === Role.systemRecruiter) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Jobs"],
          },
          // {
          //   _name: "CSidebarNavItem",
          //   name: "Add Job",
          //   to: "/add-job",
          //   icon: "cil-library",
          // },
          {
            _name: "CSidebarNavItem",
            name: "List Jobs",
            to: "/job-list",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavTitle",
            _children: ["Job Board"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Active Jobs",
            to: "/jobs-list/status/active",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Inactive Jobs",
            to: "/jobs-list/status/inactive",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Archive Jobs",
            to: "/jobs-list/status/archive",
            icon: "cil-library",
          },
        ];
      } else if (
        role === Role.customerAdmin ||
        role === Role.customerRecruiter
      ) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Jobs"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Job",
            to: "/add-job?step=0",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "List Jobs",
            to: "/job-list",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavTitle",
            _children: ["Job Board"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Active Jobs",
            to: "/jobs-list/status/active",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Inactive Jobs",
            to: "/jobs-list/status/inactive",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Archive Jobs",
            to: "/jobs-list/status/archive",
            icon: "cil-library",
          },
        ];
      }
      /*
      <!-- Hiding this, Job Board to be launched later - by venki -->
        else if (role === Role.systemCandidate || role === Role.customerCandidate) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Jobs"]
          },
          {
            _name: "CSidebarNavItem",
            name: "Job board",
            to: "/public/job-board",
            icon: "cil-library"
          }
        ];
      } */
      return [];
    },
    jobTemplatesNav() {
      let role = this.getRoleScopes[0];

      if (role === Role.customerAdmin || role === Role.customerRecruiter) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Job Templates"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Job Templates",
            to: "/list-job-templates",
            icon: "cil-library",
          },
        ];
      }
      return [];
    },
    facilityNav() {
      let role = this.getRoleScopes[0];
      // hot fix in production
      // if customerAdmin or customerRecruiter for now we're removing facility menu
      if (role === Role.systemAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Facilities"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Facility",
            to: "/add-facility",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Reports",
            to: "/facility-list",
            icon: "cil-library",
          },
        ];
      } else if (role === Role.customerAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Facilities"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Facility Details",
            to: "/facility-detail",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Facility Admin / User",
            to: "/add-facilityuser",
            icon: "cil-library",
          },
        ];
      }

      return [];
    },
    customerNav() {
      let role = this.getRoleScopes[0];
      if (role === Role.systemAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Customers"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Customer",
            to: "/add-customer",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "Customer List",
            to: "/customer-list",
            icon: "cil-library",
          },
        ];
      }

      return [];
    },
    organisationNav() {
      if (this.getIsParentUser === true) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Organisation"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add Organisation",
            to: "/add-organisation",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "List Organisation",
            to: "/organisation-list",
            icon: "cil-library",
          },
        ];
      }
      return [];
    },
    contactUserNav() {
      let role = this.getRoleScopes[0];
      if (role === Role.customerAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Users"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Add User",
            to: "/add-contactuser",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "List User",
            to: "/list-contactuser",
            icon: "cil-library",
          },
        ];
      }
      return [];
    },
    settings() {
      let role = this.getRoleScopes[0];
      if (role === Role.customerAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Setting"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Config Fields",
            to: "/configurable-fields",
            icon: "cil-library",
          },
        ];
      }
      return [];
    },
    config() {
      let role = this.getRoleScopes[0];
      if (role === Role.customerAdmin) {
        return [
          {
            _name: "CSidebarNavTitle",
            _children: ["Config"],
          },
          {
            _name: "CSidebarNavItem",
            name: "Public Job Board",
            to: "/config-public-job-board",
            icon: "cil-library",
          },
          {
            _name: "CSidebarNavItem",
            name: "OnBoard Docs",
            to: "/onboarding-documents",
            icon: "cil-library",
          },
        ];
      }

      return [];
    },
    sideBarNav() {
      let role = this.getRoleScopes[0];
      if (role === Role.systemCandidate || role === Role.customerCandidate) {
        return [
          {
            _name: "CSidebarNav",
            _children: [...this.jobsNav],
          },
        ];
      } else {
        if (this.getUserAcl?.length) {
          let returnData = [];
          for (const menu of this.getUserAcl) {
            returnData = [
              ...returnData,
              {
                _name: "CSidebarNavTitle",
                _children: [menu.menu],
              },
            ];
            for (const item of menu.menu_items) {
              let encodedItem = item.toLowerCase().replaceAll(" ", "_");
              if (
                [
                  "view/edit_job_board",
                  "organisation",
                  "users",
                  "config",
                  "job_templates",
                ].includes(encodedItem)
              ) {
                returnData = [
                  ...returnData,
                  ...this.getItemWithSubMenu(encodedItem),
                ];
              } else {
                returnData = [
                  ...returnData,
                  {
                    _name: "CSidebarNavItem",
                    name: item,
                    to: this.getPathForMenu(encodedItem),
                    icon: "cil-library",
                  },
                ];
              }
            }
          }
          return [
            {
              _name: "CSidebarNav",
              _children: [...returnData],
            },
          ];
        } else {
          return [
            {
              _name: "CSidebarNav",
              _children: [
                ...this.dashboardNav,
                ...this.candidateNav,
                ...this.customerNav,
                // ...this.hospitalNav
                //Temporarily Removed for R1 release
                //...this.facilityNav,
                ...this.jobsNav,
                ...this.organisationNav,
                ...this.contactUserNav,
                ...this.jobTemplatesNav,
                // ...this.settings,
                //Temporarily Removed for R1 release
                ...this.config,
              ],
            },
          ];
        }
      }
    },
    isDesktopView() {
      return this.windowWidth > 999;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      this.show = !this.isDesktopView && this.show;
    },
    getItemWithSubMenu(item) {
      let data = [];
      switch (item) {
        case "view/edit_job_board":
          data = [
            {
              _name: "CSidebarNavTitle",
              _children: ["View/Edit Job Board"],
            },
            {
              _name: "CSidebarNavItem",
              name: "Active Jobs",
              to: "/jobs-list/status/active",
              icon: "cil-library",
            },
            {
              _name: "CSidebarNavItem",
              name: "Inactive Jobs",
              to: "/jobs-list/status/inactive",
              icon: "cil-library",
            },
            {
              _name: "CSidebarNavItem",
              name: "Archive Jobs",
              to: "/jobs-list/status/archive",
              icon: "cil-library",
            },
          ];
          break;
        case "organisation":
          data = [
            {
              _name: "CSidebarNavTitle",
              _children: ["Organisation"],
            },
            {
              _name: "CSidebarNavItem",
              name: "Add Organisation",
              to: "/add-organisation",
              icon: "cil-library",
            },
            {
              _name: "CSidebarNavItem",
              name: "List Organisation",
              to: "/organisation-list",
              icon: "cil-library",
            },
          ];
          break;
        case "users":
          data = [
            {
              _name: "CSidebarNavTitle",
              _children: ["Users"],
            },
            {
              _name: "CSidebarNavItem",
              name: "Add User",
              to: "/add-contactuser",
              icon: "cil-library",
            },
            {
              _name: "CSidebarNavItem",
              name: "List User",
              to: "/list-contactuser",
              icon: "cil-library",
            },
          ];
          break;
        case "job_templates":
          data = [
            {
              _name: "CSidebarNavTitle",
              _children: ["Job Templates"],
            },
            {
              _name: "CSidebarNavItem",
              name: "Job Templates",
              to: "/list-job-templates",
              icon: "cil-library",
            },
          ];
          break;
        case "config":
          data = [
            {
              _name: "CSidebarNavTitle",
              _children: ["Config"],
            },
            {
              _name: "CSidebarNavItem",
              name: "Public Job Board",
              to: "/config-public-job-board",
              icon: "cil-library",
            },
            {
              _name: "CSidebarNavItem",
              name: "Document Library",
              to: "/onboarding-documents",
              icon: "cil-library",
            },
            {
              _name: "CSidebarNavItem",
              name: "Email Templates",
              to: "/email-templates",
              icon: "cil-library",
            },            
            {
              _name: "CSidebarNavItem",
              name: "Campaign Form",
              to: "/campaign-register",
              icon: "cil-library",
            },
          ];
          break;

        default:
          break;
      }
      return data;
    },
    getPathForMenu(menu) {
      switch (menu) {
        case "dashboard":
          return "/recruiter-dashboard";
        case "recruiter_dashboard":
          return "/recruiter-dashboard";
        case "add_candidate":
          return "/add-new-candidate";
        case "talent_pool":
          return this.getCandidateListUrlBasedOnRole;
        case "recruitment_status":
          return "/job-candidates-status?tab=allTab";
        case "add_customer":
          return "/add-customer";
        case "customer_list":
          return "/customer-list";
        case "create_job":
          return "/add-job?step=0";
        case "job_list":
          return "/job-list?published=%5B27,28,29%5D";
        case "allocate_job":
          return "/job-allocation";
        case "job_templates":
          return "/list-job-templates";
        case "theme_and_logo":
          return "/theme-and-logo";
        case "onboarding_docs":
          return "/onboarding-documents";
        case "management_report":
          return "/management-report";
        case "document_library":
          return "/onboarding-documents";
        case "course_list":
          return "/list-course";
        case "create_course":
          return "/add-course";

        default:
          return this.getCandidateListUrlBasedOnRole;
      }
    },
  },
  mounted() {
    this.$root.$on("toggle-sidebar", () => {
      const sidebarOpened = this.show === true || this.show === "responsive";
      this.show = sidebarOpened ? false : "responsive";
    });
    this.$root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = this.show === "responsive" || this.show === false;
      this.show = sidebarClosed ? true : "responsive";
    });
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
