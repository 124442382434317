<template>
  <div>
    <TheSidebar v-if="getUserData"/>
    <div class="c-wrapper">
      <div ref="cbody" id="c-body-app" class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
    </div>
    <!-- <TheFooter></TheFooter> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheSidebar from "./TheSidebar";
import TheFooter from "./TheFooter";
import { getScope } from "../helpers/helper";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheFooter
  },
  computed: {
    ...mapGetters(["scrollTop", "getUserData"]),
    hasFooter() {
      let footer = ["Jobs", "Candidates"];
      let { name } = this.$route;
      return !footer.includes(name);
    }
  },
  watch: {
    scrollTop() {
      if (this.scrollTop) {
        const cbody = this.$refs.cbody;
        if (cbody) cbody.scrollTo(0, 0);
      }
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
